import React, { useCallback, useEffect, useMemo, useState } from 'react';
import cn from 'classnames';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import LanguageToggle from './LanguageToggle';
import Link from 'next/link';
import ScrollIntoView from 'react-scroll-into-view';
import styles from './Header.module.scss';
import buttonStyles from '../Button/Button.module.scss';
import Button from '../Button';

const Header: React.FC<any> = () => {
  const [burgerActive, burgerToggle] = useState(false);
  const [burgerInnerVisible, burgerToggleInnerVisible] = useState(false);

  const router = useRouter();

  const [isActive, setIsActive] = useState(false);

  const { t } = useTranslation('header');

  const isHomePage = useMemo(() => router.pathname === '/', [router.pathname]);

  const headerClassName = useMemo(() => {
    return cn(
      styles.header,
      { [styles.isActive]: isActive },
      {
        [styles.products]: router.pathname === '/products/[id]',
      },
      {
        [styles.static]:
          router.pathname === '/order' || router.pathname === '/promo-quize',
      }
    );
  }, [isActive]);

  const menuLinks = ['#advantages'];
  const menuLinksMobile = ['#advantages', '#feedback'];

  const setBurgerActive = (isActive: boolean) => {
    const body = document.querySelector('body');

    if (isActive) {
      burgerToggle(isActive);
      body.classList.add('lock');

      setTimeout(() => {
        burgerToggleInnerVisible(isActive);
      }, 100);

      return;
    }

    burgerToggleInnerVisible(isActive);

    setTimeout(() => {
      burgerToggle(isActive);
      body.classList.remove('lock');
    }, 300);
  };

  return (
    <header className={headerClassName}>
      <div className='container'>
        <div className={styles.header__wrapper}>
          <Link href='/'>
            <a>
              <img
                src='/images/idBilliards-logo-2.svg'
                alt='idBall'
                className={styles.header__logo}
              />
            </a>
          </Link>
          <div className={styles.header__list}>
            <nav className={styles.header__menu}>
              <ul className={styles.header__menuList}>
                {menuLinks.map((menuLink: string, index: number) => (
                  <li className={styles.header__menuItem} key={index}>
                    {isHomePage ? (
                      <ScrollIntoView selector={menuLink}>
                        <Link href='/'>
                          <a
                            onClick={event => {
                              event.preventDefault();
                              setBurgerActive(false);
                            }}
                            className={styles.header__menuLink}
                          >
                            {t(`header.menu.${index}`)}
                          </a>
                        </Link>
                      </ScrollIntoView>
                    ) : (
                      <Link href={`/${menuLink}`}>
                        <a className={styles.header__menuLink}>
                          {t(`header.menu.${index}`)}
                        </a>
                      </Link>
                    )}
                  </li>
                ))}
                <li className={styles.header__menuItem}>
                  <Link
                    href={`https://api.whatsapp.com/send?phone=74951315496`}
                  >
                    <a className={styles.header__menuLink} target={'_blank'}>
                      {t(`header.contact_us`)}
                    </a>
                  </Link>
                </li>
              </ul>
            </nav>
            {isHomePage ? (
              <ScrollIntoView selector={'#feedback'}>
                <Button
                  placeholder={t(`header.demo`)}
                  addStyles={[buttonStyles.button__min]}
                />
              </ScrollIntoView>
            ) : (
              <Button
                placeholder={t(`header.demo`)}
                addStyles={[buttonStyles.button__min]}
              />
            )}
            <LanguageToggle isMobile={false} />
          </div>
          <div
            className={cn(styles.header__burger, {
              [styles.active]: burgerActive,
            })}
            onClick={() => setBurgerActive(!burgerActive)}
          >
            <span />
          </div>
          {burgerActive && (
            <div className={styles.header__mobileWrapper}>
              <div
                className={cn(styles.header__mobileBg, {
                  [styles.header__mobileBgVisible]: burgerInnerVisible,
                })}
                onClick={() => setBurgerActive(false)}
              />
              <nav
                className={cn(styles.header__mobileMenu, {
                  [styles.header__mobileMenuVisible]: burgerInnerVisible,
                })}
              >
                <div
                  className={styles.header__close}
                  onClick={() => setBurgerActive(false)}
                >
                  <img src={'/images/icons/close_icon.svg'} />
                </div>
                <ul className={styles.header__mobileMenuInner}>
                  {menuLinksMobile.map((menuLink: string, index: number) => (
                    <li className={styles.header__menuItem} key={index}>
                      {isHomePage ? (
                        <ScrollIntoView selector={menuLink}>
                          <Link href='/'>
                            <a
                              onClick={event => {
                                event.preventDefault();
                                setBurgerActive(false);
                              }}
                              className={styles.header__menuLink}
                            >
                              {t(`header.menu_mobile.${index}`)}
                            </a>
                          </Link>
                        </ScrollIntoView>
                      ) : (
                        <Link href={`/${menuLink}`}>
                          <a className={styles.header__menuLink}>
                            {t(`header.menu_mobile.${index}`)}
                          </a>
                        </Link>
                      )}
                    </li>
                  ))}
                </ul>
                <LanguageToggle isMobile={true} />
                <Link href={'https://api.whatsapp.com/send?phone=74951315496'}>
                  <a className={styles.header__whatsapp} target={'_blank'}>
                    <img src={'images/icons/whatsapp.svg'} alt={'Whatsapp'} />
                  </a>
                </Link>
              </nav>
            </div>
          )}
        </div>
      </div>
    </header>
  );
};

export default Header;
