let ms: any = {};

function getItem<T>(key: string): T {
  return key in ms ? ms[key] : null;
}

function setItem<T>(key: string, value: T): boolean {
  ms[key] = value;
  return true;
}

function removeItem(key: string): boolean {
  const found = key in ms;
  if (found) {
    return delete ms[key];
  }
  return false;
}

function clear(): boolean {
  ms = {};
  return true;
}

export default {
  getItem,
  setItem,
  removeItem,
  clear,
};
