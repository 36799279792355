function parseValue(rawValue: string): string {
  try {
    return JSON.parse(rawValue);
  } catch (err) {
    return rawValue;
  }
}

function parse(rawValue: string): string | null {
  const parsed = parseValue(rawValue);

  if (parsed === undefined) {
    return null;
  }

  return parsed;
}

export default parse;
