import React, { useMemo } from 'react';
import cn from 'classnames';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import ScrollIntoView from 'react-scroll-into-view';
import Link from 'next/link';

import styles from './Footer.module.scss';

const Footer: React.FC<any> = () => {
  const { t } = useTranslation('footer');

  const router = useRouter();

  const isHomePage = useMemo(() => router.pathname === '/', [router.pathname]);

  const menuLinks = ['#advantages', '#feedback'];

  const { locale: currentLanguage } = router as {
    locale: string;
  };

  return (
    <footer className={styles.footer}>
      <div className='container'>
        <div className={styles.footer__list}>
          <div className={styles.footer__item}>
            <Link href={'/'}>
              <img
                src='/images/idBilliards-logo.svg'
                alt='idBilliards'
                className={styles.footer__logo}
              />
            </Link>
          </div>
          <nav className={styles.footer__item}>
            <ul className={styles.footer__menuList}>
              {menuLinks.map((menuLink: string, index: number) => (
                <li className={styles.footer__menuItem} key={index}>
                  {isHomePage ? (
                    <ScrollIntoView selector={menuLink}>
                      <Link href='/'>
                        <a
                          className={cn(
                            styles.footer__menuLink,
                            styles.footer__whiteLink
                          )}
                          onClick={event => {
                            event.preventDefault();
                          }}
                        >
                          {t(`menu.${index}`)}
                        </a>
                      </Link>
                    </ScrollIntoView>
                  ) : (
                    <Link href={`/${menuLink}`}>
                      <a
                        className={cn(
                          styles.footer__menuLink,
                          styles.footer__whiteLink
                        )}
                      >
                        {t(`menu.${index}`)}
                      </a>
                    </Link>
                  )}
                </li>
              ))}
              <li className={styles.footer__menuItem}>
                <Link href={`https://api.whatsapp.com/send?phone=74951315496`}>
                  <a
                    className={cn(
                      styles.footer__menuLink,
                      styles.footer__whiteLink
                    )}
                    target={'_blank'}
                  >
                    {t(`contact_us`)}
                  </a>
                </Link>
              </li>
            </ul>
          </nav>
          <div className={cn(styles.footer__item, styles.footer__contacts)}>
            <Link href={'mailto:hello@idsport.ai'}>
              <a className={cn(styles.footer__mail, styles.footer__greenLink)}>
                hello@idsport.ai
              </a>
            </Link>
            <Link href={`https://api.whatsapp.com/send?phone=74951315496`}>
              <a
                className={cn(
                  styles.footer__whatsapp,
                  styles.footer__whiteLink
                )}
                target={'_blank'}
              >
                <svg
                  role={'img'}
                  focusable={'false'}
                  dangerouslySetInnerHTML={{
                    __html:
                      '<use xlink:href="/images/icons/whatsapp.svg#whatsapp" />',
                  }}
                />
                +7 (495) 131-54-96
              </a>
            </Link>
          </div>
        </div>
        <div className={cn(styles.footer__list, styles.footer__listLine)}>
          <div className={cn(styles.footer__item)}>
            <p className={styles.footer__copyright}>{t('copyright')}</p>
          </div>
          <div className={cn(styles.footer__item, styles.footer__privacy)}>
            <Link href='/privacy'>
              <a target={'_blank'} className={styles.footer__privacyLink}>
                {t('privacy')}
              </a>
            </Link>
            <Link href='/terms'>
              <a target={'_blank'} className={styles.footer__privacyLink}>
                {t('policy')}
              </a>
            </Link>
            <Link href='/license'>
              <a target={'_blank'} className={styles.footer__privacyLink}>
                {t('data')}
              </a>
            </Link>
          </div>
          <div className={cn(styles.footer__item, styles.footer__itemCompany)}>
            <img
              src='/images/footer-friflex-logo.svg'
              alt=''
              className={styles.footer__friflexLogo}
            />
            <div className={styles.footer__developedBy}>
              {t('developed_by')}&nbsp;
              <Link href={'https://friflex.com/'}>
                <a target={'_blank'} className={styles.footer__greenLink}>
                  Friflex
                </a>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
