import { ModalData } from 'interfaces/Modal/Modal';
import React, { useEffect } from 'react';
import Button from '../Button';
import { useTranslation } from 'next-i18next';

import styles from './Modal.module.scss';
import buttonStyles from '../Button/Button.module.scss';

interface ModalProps extends ModalData {
  showModal: any;
}

const Modal: React.FC<ModalProps> = ({ showModal, title, buttonText }) => {
  useEffect(() => {
    document.querySelector('body').classList.add('lock');
  });

  const handleCloseModal = () => {
    showModal(false);
    document.querySelector('body').classList.remove('lock');
  };

  const { t } = useTranslation('feedback');

  return (
    <div className={styles.modal}>
      <div className={styles.modal__bg} onClick={handleCloseModal} />
      <div className={styles.modal__content}>
        <h2 className={styles.modal__title}>{title}</h2>
        <div className={styles.modal__button}>
          <Button
            placeholder={buttonText}
            onClick={handleCloseModal}
            addStyles={[buttonStyles.button__w100, buttonStyles.button__min]}
          />
        </div>
        <div className={styles.modal__closeButton} onClick={handleCloseModal}>
          {t('feedback.modal.closeButton')}
          <img
            src={'/images/icons/close_icon.svg'}
            alt={'close'}
            className={styles.modal__closeImg}
          />
        </div>
      </div>
    </div>
  );
};

export default Modal;
