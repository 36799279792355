import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import Footer from 'components/blocks/Footer';
import Header from 'components/blocks/Header';
import favicon from 'public/images/favicon.ico';
import JivoRu from 'public/metrics/JivoRu';
import JivoEn from 'public/metrics/JivoEn';
import { get, set } from 'helpers/local-storage';
import { createOrder, getOrderData } from 'services/checkoutService';
import { setOrderData } from 'stores/orderStore';
import { useDispatch } from 'react-redux';

interface MainLayoutPageProps {
  children?: any;
  title?: string;
}

const Layout: React.FC<MainLayoutPageProps> = props => {
  const { children } = props;
  const router = useRouter();
  const { t } = useTranslation('common');
  const existingOrderId = get('idChessOrderId');
  const dispatch = useDispatch();
  const [currentOrderId, setCurrentOrderId] = useState(existingOrderId);

  const lang = ['ru'];

  useEffect(() => {
    if (currentOrderId) {
      fetchOrderData();
    }
  }, [currentOrderId]);

  useEffect(() => {
    if (router.pathname === '/order') {
      if (!currentOrderId) {
        prepareOrder();
      }
    }
  }, [currentOrderId]);

  const fetchOrderData = useCallback(async () => {
    const response = await getOrderData(currentOrderId);

    if (response.status === 200) {
      const orderData = response.data;

      dispatch(setOrderData(orderData));
    }
  }, [currentOrderId]);

  const prepareOrder = useCallback(async () => {
    const response = await createOrder();

    if (response.status === 200 || response.status === 201) {
      const orderData = response.data;

      dispatch(setOrderData(orderData));
      set('idChessOrderId', orderData?.id);
      setCurrentOrderId(orderData?.id);
    }
  }, []);

  const ogUrl = useMemo<string>(() => {
    if (process.browser) {
      return window.location.href;
    }

    return router.asPath;
  }, []);

  const ogImage = useMemo(() => {
    return `/og/open_graph_${router.locale}.png`;
  }, [router.locale]);

  return (
    <>
      <Head>
        <link rel='shortcut icon' href={favicon} type='image/x-icon' />
        <meta content='website' property='og:type' />
        <meta content={t('meta.og.title')} property='og:title' />
        <meta content={t('meta.og.description')} property='og:description' />
        <meta content={ogImage} property='og:image' />
        <meta content={ogUrl} property='og:url' />
        <meta name='description' content={t('meta.og.description')} />
        <title>{t('meta.og.title')}</title>

        <link
          rel='apple-touch-icon'
          sizes='180x180'
          href='/images/apple-touch-icon.png'
        />
        <link
          rel='icon'
          type='image/png'
          sizes='32x32'
          href='/images/favicon-32x32.png'
        />
        <link
          rel='icon'
          type='image/png'
          sizes='16x16'
          href='/images/favicon-16x16.png'
        />
        <link
          rel='mask-icon'
          href='/images/safari-pinned-tab.svg'
          color='#5bbad5'
        />
        <meta name='theme-color' content='#050505' />

        {lang.map(value => (
          <link
            key={value}
            rel='alternate'
            hrefLang={value}
            href={`${process.env.NEXT_PUBLIC_API_HOST}/${
              value + router.pathname
            }`}
          />
        ))}
        <link
          rel='alternate'
          hrefLang='x-default'
          href={`${process.env.NEXT_PUBLIC_API_HOST + router.pathname}`}
        />
      </Head>
      <div className='wrapper'>
        <Header />
        <div className='wrapper-content'>{children}</div>
        <Footer />
      </div>
      {router.locale === 'ru' ? <JivoRu /> : <JivoEn />}
    </>
  );
};

export default Layout;
