import { AxiosError, AxiosResponse } from 'axios';
import { httpClient } from 'helpers/httpClient';

type CreateOrderResponse = any & { errors?: any };

interface UpdateOrderResponse {
  success?: boolean;
  errors?: any;
}

export const createOrder = async (): Promise<
  AxiosResponse<CreateOrderResponse>
> => {
  try {
    const response = await httpClient.post<CreateOrderResponse>('/orders');

    return response;
  } catch (e) {
    return e.response as AxiosError<unknown> as never;
  }
};

export const updateOrderData = async (
  orderId: number | string,
  data?: any
): Promise<AxiosResponse<UpdateOrderResponse>> => {
  try {
    const response = await httpClient.patch<UpdateOrderResponse>(
      `/orders/${orderId}`,
      { order_information: data }
    );

    return response;
  } catch (e) {
    return e.response as AxiosError<unknown> as never;
  }
};

export const getOrderData = async (
  orderId: number | string
): Promise<AxiosResponse<CreateOrderResponse>> => {
  try {
    const response = await httpClient.get<CreateOrderResponse>(
      `/orders/${orderId}`
    );

    return response;
  } catch (e) {
    return e.response as AxiosError<unknown> as never;
  }
};

export const addOrderProduct = async (
  orderId: number | string,
  productId: number | string,
  productCount: number
): Promise<AxiosResponse<CreateOrderResponse>> => {
  try {
    const response = await httpClient.post<CreateOrderResponse>(
      `/orders/${orderId}/product/${productId}`,
      { count: productCount }
    );

    return response;
  } catch (e) {
    return e.response as AxiosError<unknown> as never;
  }
};

export const updateOrderProduct = async (
  orderId: number | string,
  productId: number | string,
  productCount: number
): Promise<AxiosResponse<CreateOrderResponse>> => {
  try {
    const response = await httpClient.patch<CreateOrderResponse>(
      `/orders/${orderId}/product/${productId}`,
      { count: productCount }
    );

    return response;
  } catch (e) {
    return e.response as AxiosError<unknown> as never;
  }
};

export const removeOrderProduct = async (
  orderId: number | string,
  productId: number | string
): Promise<AxiosResponse<CreateOrderResponse>> => {
  try {
    const response = await httpClient.delete<CreateOrderResponse>(
      `/orders/${orderId}/product/${productId}`
    );

    return response;
  } catch (e) {
    return e.response as AxiosError<unknown> as never;
  }
};
