import stub from './stub';
import parse from './parse';

function lsTest(): boolean {
  const test = 'test';

  try {
    localStorage.setItem(test, test);
    localStorage.removeItem(test);
    return true;
  } catch (e) {
    return false;
  }
}

const lsIsAvailable = lsTest();
const ls =
  typeof window !== 'undefined' &&
  'localStorage' in window &&
  window.localStorage &&
  lsIsAvailable
    ? window.localStorage
    : stub;

export function get(key: string): string | null {
  const raw: any = ls.getItem(key);

  if (raw) {
    return parse(raw);
  }

  return null;
}

export function set<T>(key: string, value: T): boolean {
  try {
    ls.setItem(key, JSON.stringify(value));
    return true;
  } catch (e) {
    return false;
  }
}

export function remove(key: string): boolean | void {
  return ls.removeItem(key);
}

function clear(): boolean | void {
  return ls.clear();
}

function accessor<T>(key: string, value: T): any {
  if (arguments.length === 1) {
    return get(key);
  }
  return set(key, value);
}

accessor.set = set;
accessor.get = get;
accessor.remove = remove;
accessor.clear = clear;

export default accessor;
